import { DefaultSeoProps } from "next-seo";

export const SEO_CONFIG: DefaultSeoProps = {
  defaultTitle: "Culinario Mortale – Das Krimi-Dinner für Zuhause",
  openGraph: {
    type: "website",
    locale: "de",
    url: "https://www.culinario-mortale.ru/",
    site_name: "Culinario Mortale® – Das Krimi-Dinner für Zuhause",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
  robotsProps: {
    notranslate: true,
  },
};
