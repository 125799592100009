import { Button, Container, Typography } from "@mui/material";
import React from "react";

export class OuterErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <p>Verzeihung, ein technischer Fehler ist aufgetreten!</p>
          <button onClick={() => this.setState({ hasError: false })}>Neu laden</button>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export class InnerErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container maxWidth="xl" sx={{ pt: 12, pb: 16 }}>
          <Typography component="h1" variant="h2" sx={{ pb: 3 }}>
            Ein technischer Fehler ist aufgetreten
          </Typography>
          <Typography variant="body1" sx={{ pb: 5, maxWidth: "90ch" }}>
            Entschuldigung, das sollte nicht passieren. Bitte versuche zunächst die Seite neu zu laden.
            Wenn das nicht hilft, schreib uns bitte eine Nachricht unter support@culinario-mortale.de und
            beschreibe uns kurz, welche Seite du aufgerufen hast, als dieser Fehler kam. Dann sehen wir
            uns das umgehend an und versuchen, dir zu helfen.
          </Typography>
          <Button variant="contained" onClick={() => this.setState({ hasError: false })}>
            Neu laden
          </Button>
        </Container>
      );
    }

    return this.props.children;
  }
}
